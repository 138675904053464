import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PhoenixBranding from 'branding-asset-primitives/phoenix/PhoenixHRALogo.png';
import HCLABranding from 'branding-asset-primitives/hcla/logo-thick-xl.png';
import CoviDeSBranding from '../../assets/covides-icon-512px.png';
import CollapseAppCard from './CollapseAppCard';
import { GridItem } from './GridItems';
import Apps from './Apps';

const styles = () => ({
});

const LevelOneApps = (props) => {
  return (
    <Apps>
      {
        process.env.INFECTION_PREDICTION_URL ?       
        <GridItem link={`${process.env.INFECTION_PREDICTION_URL}`}>
        <CollapseAppCard
          title="CoviDeS"
          subheader="COVID-19 Decision Support Tool"
          description="Simulation tool for exploring COVID-19 scenarios and
          outcomes for a given community as a function of mitigation measures and policies.
          Utilizes a hybrid model covering dynamic interactions of epidemiological,
          psychological, demographic, policy and factors."
          image={CoviDeSBranding}
        />
      </GridItem> : null
      }
      {
        process.env.PHOENIX_URL ? 
        <GridItem link={`${process.env.PHOENIX_URL}`}>
        <CollapseAppCard
          title="Phoenix"
          subheader="Human Reliability Assessment Tool"
          description="Phoenix HRA is used to understand and assess the effect of human behavior on system risk,
              thereby incorporating this into PRA with the overall goals of assessing and reducing the likelihood and
              consequences of errors made by humans."
          image={PhoenixBranding}
        />
      </GridItem> : null
      }
      {
        process.env.HCLA_URL ?       
        <GridItem link={`${process.env.HCLA_URL}`}>
        <CollapseAppCard
          title="HCLA"
          subheader="Hybrid Causal Logic Analyzer - PRA Tool"
          description="HCLA is an application used to design the system architecture and perform
              traditional probabilistic risk assessment (PRA) with time-to-failure models, importance measures, and
              uncertainty quantification."
          image={HCLABranding}
        />
      </GridItem> : null
      }
    </Apps>
  );
};

LevelOneApps.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(LevelOneApps);
